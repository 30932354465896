import { Icon, Paper, Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Exit as ExitIcon } from "../../icons/dashboardIcons";
import { ArrowDropUp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getNumberWithCommas } from "../../helper/getNumberWithCommas";
import NoData from "./NoData";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import ConnectShopMessage from "./ConnectShopMessage";

const useStyles = makeStyles(
  (theme) => ({
    outerWrapper: {
      height: "540px",
      width: "100%",
      overflowY: "hidden",
      "&.MuiPaper-root": {
        borderRadius: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        "&.MuiPaper-root": {
          borderRadius: "14px",
        },
      },
    },
    titleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: 20,
    },
    mainWrapper: {
      height: "480px",
      overflowY: "auto",
      display: "flex",
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 5,
      flexDirection: "column",
      gap: 30,
    },
  }),
  {
    name: "MuiCustomTop",
  }
);
const TopSellingComponent = ({
  data,
  currencySymbol,
  isLoading,
  timeRange,
}) => {
  const {
    salesChannelsState: { userAccountDetails, sellerSalesChannelsDetails },
  } = useSalesChannel();
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={classes.outerWrapper}
      sx={{
        backgroundColor: "background.secondary",
      }}
    >
      <Box className={classes.titleWrapper}>
        <Typography fontSize="16px" fontWeight="500">
          Top Selling products
        </Typography>
        {/* <Icon sx={{ height: "fit-content" }}>
          <ExitIcon />
        </Icon> */}
      </Box>
      {/* // wrapper*/}
      {!sellerSalesChannelsDetails[0]?.sales_channel_shop_name && (
        <ConnectShopMessage />
      )}
      {data?.length == 0 && (
        <Box sx={{ marginTop: "50px" }}>
          <NoData />
        </Box>
      )}

      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src="/loadingT.gif"
            alt="loader"
            sx={{ width: "100px" }}
          />
        </Box>
      ) : (
        <Box className={classes.mainWrapper}>
          {/* //Individual Product */}
          {data
            ?.sort((a, b) => b.total_revenue - a.total_revenue)
            ?.map((value) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    filter: userAccountDetails?.hide_details && "blur(6px)",
                  }}
                >
                  <img
                    src={value.Images}
                    alt="product"
                    className="analytics-table-photo"
                  />
                </Box>
                <a
                  target="_blank"
                  href={`https://www.etsy.com/your/shops/me/stats/listings/${value.listing_id}?date_range=${timeRange}`}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      filter: userAccountDetails?.hide_details && "blur(4px)",
                    }}
                  >
                    <Typography
                      fontWeight="400"
                      fontSize="14px"
                      lineHeight="21px"
                      sx={{
                        maxWidth: { xs: "100px", sm: "380px", md: "400px" },
                        textDecoration: "none",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {value.title.replaceAll("&#39;", "'")}
                    </Typography>
                    <Typography
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="18px"
                      color="grayShades.main"
                    >
                      {getNumberWithCommas(value.total_sales)} sold
                    </Typography>
                  </Box>
                </a>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    minWidth: "100px",
                    ml: "auto",
                    gap: "7px",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="21px"
                  >
                    <Typography
                      component="span"
                      fontSize="13px"
                      fontWeight="500"
                      fontFamily="sans-serif"
                    >
                      {currencySymbol}
                    </Typography>
                    {getNumberWithCommas(value.total_revenue)}
                  </Typography>
                  <ArrowDropUp sx={{ color: "greenColor.light" }} />
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </Paper>
  );
};

export default TopSellingComponent;
