import { Avatar, Box, Link, Paper, Typography } from "@mui/material";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { newsData } from "./newsData";

const NewNewsComponent = () => {
  const {
    salesChannelsState: { salesChannelsDetails },
  } = useSalesChannel();
  return (
    <Box
      sx={{
        backgroundColor: "background.secondary",
        padding: "10px",
        borderRadius: { xs: "9px", sm: "20px" },
        height: salesChannelsDetails?.length ? "436px" : "65%",
      }}
    >
      <Typography
        fontWeight="500"
        fontSize="16px"
        lineHeight="19px"
        color="fontColor.fontColorPrimary"
        sx={{
          marginBottom: "10px",
          marginLeft: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        Etsy News
      </Typography>
      <Box
        sx={{
          height: salesChannelsDetails?.length > 0 ? "380px" : "90%",
          overflow: "auto",
        }}
      >
        {newsData.map((newsItem) => (
          <Paper
            elevation={0}
            sx={{
              padding: "10px",
              margin: "20px 5px",
              backgroundColor: "background.secondary",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Box
                component="img"
                src={newsItem.image}
                sx={{
                  height: { xs: "59px", sm: "91px" },
                  width: { xs: "59px", sm: "91px" },
                  borderRadius: { xs: "2.87px", sm: "5px" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: { xs: "1px", sm: "3px" },
                }}
              >
                <Typography
                  color="fontColor.lightAsh"
                  sx={{
                    fontSize: { xs: "6px", sm: "9px" },
                    lineHeight: { xs: "7px", sm: "12px" },
                  }}
                >
                  {newsItem.date}
                </Typography>
                <Typography
                  fontWeight="600"
                  sx={{
                    fontSize: { xs: "9px", sm: "12px" },
                    lineHeight: { xs: "10px", sm: "18px" },
                  }}
                >
                  {newsItem.title}
                </Typography>

                <Typography
                  color="fontColor.lightAsh"
                  sx={{ fontSize: { xs: "7px", sm: "10px" } }}
                >
                  {newsItem.body}
                </Typography>
                <Typography sx={{ fontSize: { xs: "7px", sm: "10px" } }}>
                  <Link
                    sx={{
                      textDecoration: "none",
                    }}
                    href={`https://www.etsy.com/seller-handbook/article/${newsItem.id}`}
                    target="_blank"
                  >
                    Read more
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default NewNewsComponent;
