import { Paper, Typography, Box, Button } from "@mui/material";
import { useState } from "react";

import Rating from "@mui/material/Rating";
import { makeStyles } from "@mui/styles";
import { date } from "yup";
import NoData from "./NoData";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import ConnectShopMessage from "./ConnectShopMessage";

const useStyles = makeStyles(
  (theme) => ({
    outerWrapper: {
      width: "100%",
      height: "440px",
      paddingRight: 0,
      overflowY: "hidden",
      "&.MuiPaper-root": {
        borderRadius: "20px",
      },
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        "&.MuiPaper-root": {
          borderRadius: "14px",
        },
      },
    },
    mainWrapper: {
      height: "370px",
      overflowY: "auto",
      display: "flex",
      marginBottom: "20px",
      marginRight: "10px",
      padding: 20,
      paddingTop: 5,
      flexDirection: "column",
      gap: 30,
    },
    headingText: {
      "&.MuiTypography-root": {
        marginX: 10,
        fontWeight: "500",
        fontSize: "16px ",
        margin: "15px",
      },
    },

    reviewDescription: {
      "&.MuiTypography-root": {
        fontStyle: "italic",
        fontWeight: "400",
        fontSize: "12px ",
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "9px ",
          lineHeight: "11px",
        },
      },
    },
    reviewTimeStamp: {
      "&.MuiTypography-root": {
        fontSize: "10px",
        fontWeight: "400",
        [theme.breakpoints.down("sm")]: {
          fontSize: "9px",
        },
      },
    },
  }),
  {
    name: "MuiCustomReviews",
  }
);
function getDateDifference(date) {
  var firstDate = new Date(new Date()),
    secondDate = new Date(date),
    firstDateInSeconds = firstDate.getTime() / 1000,
    secondDateInSeconds = secondDate.getTime() / 1000,
    difference = Math.abs(firstDateInSeconds - secondDateInSeconds);
  if (difference < 60) {
    return difference + " seconds";
  } else if (difference < 60 * 60) {
    return Math.floor(difference / 60) + " minutes";
  } else if (difference < 60 * 60 * 24) {
    return Math.floor(difference / (60 * 60)) + " hours";
  } else if (difference < 60 * 60 * 24 * 30) {
    return Math.floor(difference / (60 * 60 * 24)) + " days";
  } else if (difference < 60 * 60 * 24 * 365) {
    return Math.floor(difference / (60 * 60 * 24 * 30)) + " months";
  } else {
    return Math.floor(difference / (60 * 60 * 24 * 365)) + " years";
  }
  return "";
}
const ReviewsComponent = ({ data, isLoading }) => {
  const classes = useStyles();
  const {
    salesChannelsState: { sellerSalesChannelsDetails },
  } = useSalesChannel();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Paper
      elevation={0}
      className={classes.outerWrapper}
      sx={{
        backgroundColor: "background.secondary",
      }}
    >
      <Typography className={classes.headingText}>Recent reviews</Typography>
      {!sellerSalesChannelsDetails[0]?.sales_channel_shop_name && (
        <ConnectShopMessage />
      )}
      {data?.length == 0 && (
        <Box>
          <NoData />
        </Box>
      )}
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src="/loadingT.gif"
            alt="loader"
            sx={{ width: "100px" }}
          />
        </Box>
      ) : (
        <Box className={classes.mainWrapper}>
          {/* Individual Wrapper */}
          {data?.map((item) => (
            <Box
              onClick={() =>
                window.open(
                  `https://www.etsy.com/in-en/listing/${item.listing_id}`,
                  "_blank"
                )
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-end", gap: "7px" }}>
                <Rating name="read-only" value={item.rating} readOnly />
                <Typography
                  className={classes.reviewTimeStamp}
                  color="fontColor.lightAsh"
                >
                  {getDateDifference(item.created_at) + " ago"}
                </Typography>
              </Box>
              <Typography
                className={classes.reviewDescription}
                color="fontColor.lightGray"
              >
                {item.review}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default ReviewsComponent;
