import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { CalendarToday } from "@mui/icons-material";
import { useRouter } from "next/router";
import Link from "next/link";
import amplitude from "amplitude-js";

const useStyles = makeStyles(
  (theme) => ({
    outerWrapper: {
      width: "100%",
      height: "385px",
      overflowY: "hidden",
      padding: 10,
      "&.MuiPaper-root": {
        borderRadius: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "430px",
      },
    },
    individualItem: {
      display: "flex",
      gap: 10,
      marginRight: "5px",
    },
    headingText: {
      "&.MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        margin: "5px 5px 15px 5px",
      },
    },
    btn: {
      "&.MuiButton-root": {
        textTransform: "capitalize",
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "18px",
        height: "36px",
        marginLeft: "auto",
      },
    },
  }),
  {
    name: "MuiCustomVideo",
  }
);

const UpComingHolidaysComponent = ({ list, isLoading }) => {
  const router = useRouter();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function handleDate(holidayDate) {
    let show = false;
    const arr = holidayDate.split("-");
    let date = new Date();
    if (
      arr[0] > date.getFullYear() ||
      date.getMonth() + 1 < parseInt(arr[1]) ||
      (date.getMonth() + 1 == parseInt(arr[1]) &&
        date.getDate() < parseInt(arr[2]))
    ) {
      show = true;
    }
    return show;
  }

  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      className={classes.outerWrapper}
      sx={{
        backgroundColor: "background.secondary",
      }}
    >
      <Typography className={classes.headingText}>Upcoming Holidays</Typography>
      {/* //Individual Item */}
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src="/loadingT.gif"
            alt="loader"
            sx={{ width: "100px" }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
            height: { xs: "225px", sm: "340px", md: "285px" },
            overflowY: "auto",
          }}
        >
          {list?.map((item) => (
            <>
              {handleDate(item.date) && (
                <Box className={classes.individualItem}>
                  <Box sx={{ alignSelf: "center" }}>
                    <CalendarToday />
                  </Box>
                  <Box>
                    <Typography
                      fontWeight="400"
                      fontSize="13px"
                      lineHeight="20px"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="18px"
                      color="grayShades.main"
                    >
                      {item.date.split("-")[2]}{" "}
                      {months[parseInt(item.date.split("-")[1]) - 1]}{" "}
                      {item.date.split("-")[0]} | {item.country}
                    </Typography>
                  </Box>
                  <Link
                    href={`/productAnalytics?tab=analyze_by_term&search_term=${item.name.toLowerCase()}&subtab=1`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.btn}
                      onClick={() =>
                        amplitude.getInstance().logEvent("Holiday Calendar", {
                          holiday_name: item.name,
                        })
                      }
                      sx={{
                        color: "fontColor.outlinedBtnFontColor",
                        width: "100px",
                        minWidth: "100px",
                      }}
                    >
                      View Products
                    </Button>
                  </Link>
                </Box>
              )}
            </>
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default UpComingHolidaysComponent;
