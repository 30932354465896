import { useRouter } from "next/router";
import { useAuth } from "./AuthContext";
import { useSalesChannel } from "../contexts/SalesChannelContext";
import Loader from "../components/Loader";

const withAuth = (Component) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const router = useRouter();
      const {
        authState: { everbeeToken, navigateToEtsy },
      } = useAuth();
      const {
        salesChannelsState: { userAccountDetails },
      } = useSalesChannel();
      if (!everbeeToken) {
        router.replace("/login");
        return <Loader isLoading={true} />;
      } else if (navigateToEtsy) {
        window.location.href = navigateToEtsy;
        return <Loader isLoading={true} />;
      }

      return <Component {...props} />;
    }
    return null;
  };
};
export default withAuth;

export const publicAuth = (Component) => {
  return (props) => {
    if (typeof window !== "undefined") {
      const router = useRouter();
      const {
        authState: { everbeeToken, navigateToEtsy },
      } = useAuth();
      if (everbeeToken) {
        if (navigateToEtsy) {
          window.location.href = navigateToEtsy;
        } else if (localStorage.getItem("everbeePrevURL")) {
          router.replace(localStorage.getItem("everbeePrevURL"));
          // localStorage.removeItem("everbeePrevURL");
        } else {
          router.replace("/");
        }
        return <Loader isLoading={true} />;
      }
      return <Component {...props} />;
    }
    // localStorage.removeItem("everbeePrevURL");
    return null;
  };
};
