import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";

const ConnectShopMessage = () => {
  const router = useRouter();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: "600", textAlign: "center" }}>
        No shop data found! Connect your Etsy seller account to see your
        insights
      </Typography>
      <Button
        variant="contained"
        size="small"
        sx={{
          marginTop: "10px",
          "&.MuiButtonBase-root": {
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "500",
            color: "#fff !important",
            backgroundColor: "checkBoxColor.main",
            padding: "5px 10px",
            cursor: "pointer",
            zIndex: "1",
          },
        }}
        onClick={() => router.push("/account")}
      >
        Connect
      </Button>
    </Box>
  );
};

export default ConnectShopMessage;
