export const newsData = [
  {
    id: "1088320907216",
    title: "Intellectual Property Rights and Resources for EU Sellers",
    body: "Insights from the European Union Intellectual Property Office, along with the relevant tools and activities intended for small businesses in the EU.",
    image:
      "https://i.etsystatic.com/inv/24d616/4315662966/inv_609x512.4315662966_nk31nmrf.jpg?version=0",
    date: "4 November 2022 ",
  },
  {
    id: "1024388930672",
    title:
      "Your November Shop Checklist and Calendar for the Festive Season 2022",
    body: "A monthly guide to key shopping dates, upcoming seller events, and buyer trends based on Etsy search data and industry forecasting.",
    image:
      "https://i.etsystatic.com/inv/ec4e5f/4211882141/inv_609x512.4211882141_ibe7qdtd.jpg?version=0",
    date: "1 November 2022",
  },
  {
    id: "1087958808206",
    title: "Your Account Security Checklist",
    body: "Use this guide to help keep your Etsy account protected. Plus, find out how to get in touch with the Etsy Support team.",
    image:
      "https://i.etsystatic.com/inv/d8b99a/4282407172/inv_609x512.4282407172_i96u0kcd.jpg?version=0",
    date: "31 October 2022",
  },
  {
    id: "1083370599698",
    title:
      "Podcast Transcript: 2022 Trends for the Festive Season and Shop Trend Critiques",
    body: "From magical forest themes to posh pearlcore looks, find out what trends are rising to the top for the busy shopping season.",
    image:
      "https://i.etsystatic.com/inv/2c76d9/4197810568/inv_609x512.4197810568_r83elb7k.jpg?version=0",
    date: "5 October 2022",
  },
  {
    id: "1106022743419",
    title: "Creating a Pricing Strategy for Your Listings",
    body: "With inflation on the rise, it’s a good time to review whether your current prices are covering the cost of your materials, time, labour, and overhead.",
    image:
      "https://i.etsystatic.com/inv/d9aab4/4209760078/inv_609x512.4209760078_15ym6v3b.jpg?version=0",
    date: "3 October 2022",
  },
  {
    id: "22815438793",
    title: "Marketing Tips for Etsy Sellers this Festive Season",
    body: "Show off everything your shop has to offer with seasonal strategies for appealing to shoppers.",
    image:
      "https://i.etsystatic.com/inv/160146/4211781765/inv_609x512.4211781765_8dki75zh.jpg?version=0",
    date: "14 September 2022",
  },
  {
    id: "22398733106",
    title: "Help Build Buyer Loyalty This Festive Season",
    body: "Check out tips for wowing shoppers with great customer service – and encouraging them to keep coming back for more.",
    image:
      "https://img0.etsystatic.com/059/0/22397459979/inv_609x512.742788156_iorfb5su.jpg",
    date: "14 September 2022",
  },
  {
    id: "973333640408",
    title: "Checklist: How to Deliver Smoothly This Festive Season",
    body: "Use this cheat sheet to help get your shop ready to fulfil orders this festive season and appeal to buyers.",
    image:
      "https://i.etsystatic.com/inv/19348c/4164154804/inv_609x512.4164154804_5x75qzdj.jpg?version=0",
    date: "14 September 2022",
  },
  {
    id: "366469719354",
    title: "Creating Listings That Convert",
    body: "Increasing your conversion rate may improve where your listings rank in Etsy search. Check out these tips for increasing your views.",
    image:
      "https://img0.etsystatic.com/256/0/385275978637/inv_609x512.1589435910_2g114m8c.jpg",
    date: "31 August 2022",
  },
  {
    id: "1099574425189",
    title: "5 Star Sellers Share the Impact of the Badge on Their Shops",
    body: "For these sellers, Star Seller provides helpful insight into their customer service – and a confidence boost. Hear about their experiences.",
    image:
      "https://i.etsystatic.com/inv/88a929/4106699912/inv_609x512.4106699912_a9q99ri5.jpg?version=0",
    date: "3 August 2022",
  },
];
