import { useState, useEffect } from "react";
import { DashboardLayout } from "../components/dashboard-layout";
import GraphComponent from "../components/dashboardComponents/GraphComponent";
import VideoComponent from "../components/dashboardComponents/VideoComponent";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import OnBoardingList from "../components/dashboardComponents/OnBoardingList";
import ReviewsComponent from "../components/dashboardComponents/ReviewsComponent";
import UpComingHolidaysComponent from "../components/dashboardComponents/UpComingHolidaysComponent";
import TopSellingComponent from "../components/dashboardComponents/TopSellingComponent";
import dateRangeConstant from "../helper/dateRangeConstant";
import Loader from "../components/Loader";
import { useSalesChannel } from "../contexts/SalesChannelContext";
import withAuth from "../contexts/protectedRoutes";
import { useRouter } from "next/router";
import {
  graphDataApi,
  upcomingHolidays,
  topSellingDataApi,
  reviewDataApi,
} from "../apis/dashboard";
import { useLoader } from "../contexts/LoaderContext";
import NewNewsComponent from "../components/dashboardComponents/NewNewsComponent";
import { dashboardStepsNew } from "../components/steps";
import { useTour } from "@reactour/tour";

const dashboard = () => {
  const { loadingSet } = useLoader();
  const [graphData, setGraphData] = useState();
  const [topSellingData, setTopSellingData] = useState();
  const [reviewData, setReviewData] = useState();
  const [graphLoading, setGraphLoading] = useState(false);
  const [topSellingLoading, setTopSellingLoading] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [holidayLoading, setHolidayLoading] = useState(false);
  const {
    salesChannelsState: {
      timeRangeSelector,
      selectedShops,
      salesChannelsDetails,
    },
  } = useSalesChannel();
  const { setSteps, steps: w, setCurrentStep } = useTour();
  const [holiday, setHoliday] = useState([]);

  useEffect(() => {
    if (selectedShops.length > 0 && timeRangeSelector) {
      getDashboardData();
    }
    getHoliday();
  }, [selectedShops, timeRangeSelector]);

  async function getHoliday() {
    try {
      setHolidayLoading(true);
      const res = await upcomingHolidays();
      setHoliday(res.data?.results);
    } catch (err) {
    } finally {
      setHolidayLoading(false);
    }
  }

  async function getTopSellingListings(timeRange) {
    try {
      const topSellingResult = await topSellingDataApi(
        timeRange,
        selectedShops
      );
      setTopSellingData(topSellingResult.data.results);
    } catch (error) {
    } finally {
      setTopSellingLoading(false);
    }
  }

  async function getReviews(timeRange) {
    try {
      const reviewResult = await reviewDataApi(timeRange, selectedShops);
      setReviewData(reviewResult.data.results);
    } catch (error) {
    } finally {
      setReviewsLoading(false);
    }
  }

  async function getGraphs(timeRange) {
    try {
      const graphResult = await graphDataApi(timeRange, selectedShops);
      setGraphData(graphResult.data.results);
    } catch (error) {
    } finally {
      setGraphLoading(false);
    }
  }

  async function getDashboardData() {
    const timeRange = dateRangeConstant(timeRangeSelector);
    setGraphLoading(true);
    setTopSellingLoading(true);
    setReviewsLoading(true);
    getTopSellingListings(timeRange);
    getReviews(timeRange);
    getGraphs(timeRange);
  }
  /* web tour */
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const matchTab = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { pathname } = useRouter();
  useEffect(() => {
    if (pathname === "/dashboard" && matchMobile) {
      setSteps([]);
    } else if (pathname === "/dashboard" && matchTab) {
      setSteps(dashboardStepsNew.slice(0, 3));
    } else {
      setSteps(dashboardStepsNew);
    }
  }, [pathname, matchMobile, matchTab]);
  return (
    <>
      <Loader isLoading={loadingSet.size !== 0} />

      {/*For Mobile and Tablet View */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: "30px",
          paddingY: { xs: 2, sm: 4, md: 4 },
          marginLeft: { sm: "90px", md: 0 },
        }}
      >
        <Grid
          container
          sx={{ display: { xs: "flex", md: "none" } }}
          spacing={2}
          justify="flex-start"
        >
          <Grid item xs={12} sm={12} order={{ xs: 1 }}>
            <GraphComponent
              data={graphData?.graph}
              currencySymbol={graphData?.currency_symbol}
              isLoading={graphLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 2 }}>
            <OnBoardingList />
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 3 }}>
            <VideoComponent />
          </Grid>
          {salesChannelsDetails?.length > 0 && (
            <Grid item xs={12} sm={12} order={{ xs: 4 }}>
              <TopSellingComponent
                data={topSellingData?.top_selling_listings}
                currencySymbol={topSellingData?.currency_symbol}
                isLoading={topSellingLoading}
                timeRange={dateRangeConstant(timeRangeSelector)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} order={{ xs: 5, sm: 6 }}>
            <UpComingHolidaysComponent
              list={holiday}
              isLoading={holidayLoading}
            />
          </Grid>
          {salesChannelsDetails?.length > 0 && (
            <Grid item xs={12} sm={12} order={{ xs: 6, sm: 7 }}>
              <ReviewsComponent data={reviewData} isLoading={reviewsLoading} />
            </Grid>
          )}
          <Grid item xs={12} sm={6} order={{ xs: 7, sm: 5 }}></Grid>
          <Grid item xs={12} sm={12} order={{ xs: 8 }}>
            {/* <NewsComponent /> */}
            <NewNewsComponent />
          </Grid>
        </Grid>
        {/*For Desktop View */}

        <Grid
          container
          sx={{ display: { xs: "none", md: "flex" } }}
          spacing={2}
          justify="flex-start"
        >
          <Grid item md={12}>
            <GraphComponent
              data={graphData?.graph}
              currencySymbol={graphData?.currency_symbol}
              isLoading={graphLoading}
            />
          </Grid>
          {salesChannelsDetails?.length > 0 ? (
            <Grid item md={8}>
              <div>
                <TopSellingComponent
                  data={topSellingData?.top_selling_listings}
                  currencySymbol={topSellingData?.currency_symbol}
                  isLoading={topSellingLoading}
                  timeRange={dateRangeConstant(timeRangeSelector)}
                />
              </div>
              <Box height={20}></Box>
              <div>
                <ReviewsComponent
                  data={reviewData}
                  isLoading={reviewsLoading}
                />
              </div>
            </Grid>
          ) : (
            <Grid item md={8} height="fit-content">
              <NewNewsComponent />
            </Grid>
          )}
          <Grid item md={4}>
            <OnBoardingList />
            <Box height={20}></Box>
            <VideoComponent />
            <Box height={20}></Box>
            <div>
              <UpComingHolidaysComponent
                list={holiday}
                isLoading={holidayLoading}
              />
            </div>
          </Grid>
          {salesChannelsDetails?.length > 0 && (
            <Grid item md={12}>
              {/* <NewsComponent /> */}
              <NewNewsComponent />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default withAuth(dashboard);
