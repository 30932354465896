import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "../../contexts/ColorModeContext";
import NextLink from "next/link";

const NoData = ({ startSelling }) => {
  const { mode } = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {mode == "light" && (
        <img src="White_Info.gif" style={{ height: "250px", width: "250px" }} />
      )}
      {mode == "dark" && (
        <img src="Black_Info.gif" style={{ height: "250px", width: "250px" }} />
      )}
      <Typography sx={{ fontWeight: "600" }}>No Data To Show!</Typography>
      {startSelling && (
        <NextLink href={`/productAnalytics?tab=analyze_by_term&search_term=`}>
          <Button
            variant="contained"
            size="small"
            sx={{
              marginTop: "10px",
              "&.MuiButtonBase-root": {
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "500",
                color: "white",
                backgroundColor: "checkBoxColor.main",
                padding: "5px 10px",
              },
            }}
          >
            Research Products
          </Button>
        </NextLink>
      )}
    </Box>
  );
};

export default NoData;
