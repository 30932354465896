import { Box, Button, Icon, Paper, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  (theme) => ({
    iframeWrapper: {
      height: "270px",
      [theme.breakpoints.down("sm")]: {
        height: "220px",
      },
    },
    videoDescription: {
      "&.MuiTypography-root": {
        fontWeight: "400",
        fontSize: "14px ",
        lineHeight: "21px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px ",
          lineHeight: "15px",
          width: "100%",
        },
      },
    },
    watchBtn: {
      "&.MuiButton-root": {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        height: "36px",
      },
      [theme.breakpoints.down("sm")]: {
        "&.MuiButton-root": {
          fontSize: "10px",
          lineHeight: "15px",
          height: "29px",
          borderRadius: "3px",
        },
      },
    },
    videoIframeShort: {
      width: "100%",
      height: "100%",
      borderTopLeftRadius: " 20px",
      borderTopRightRadius: " 20px",
      [theme.breakpoints.down("sm")]: {
        borderTopLeftRadius: " 14px",
        borderTopRightRadius: " 14px",
      },
    },
  }),
  {
    name: "MuiCustomStyle",
  }
);
const VideoComponent = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        backgroundColor: "background.secondary",
        borderRadius: "20px",
      }}
    >
      <Box className={classes.iframeWrapper}>
        <iframe
          className={classes.videoIframeShort}
          src={`https://www.youtube-nocookie.com/embed/9qU2dky3h-k?autoplay=0&mute=1&modestbranding=0&fs=1&showinfo=0`}
          allowfullscreen="true"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
        ></iframe>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "10px 15px",
          alignItems: "center",
        }}
      >
        <Typography
          color="fontColor.fontColorPrimary"
          className={classes.videoDescription}
        >
          Watch how EverBee’s Dashboard will help Increase your product’s
          conversion rate.
        </Typography>
        <Button
          variant="outlined"
          size="large"
          className={classes.watchBtn}
          sx={{
            color: "fontColor.outlinedBtnFontColor",
          }}
        >
          Watch
        </Button>
      </Box>
    </Box>
  );
};

export default VideoComponent;
