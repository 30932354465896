import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {
  CancelRounded,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useExtension } from "../../contexts/ExtensionContext";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles(
  (theme) => ({
    outerWrapper: {
      width: "100%",
      height: "auto",
      overflowY: "auto",
      overflowX: "hidden",
      "&.MuiPaper-root": {
        position: "relative",
        borderRadius: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {},
      [theme.breakpoints.down("sm")]: {
        "&.MuiPaper-root": {
          borderRadius: "14px",
        },
      },
    },
    headingText: {
      "&.MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
    },
    checkboxWrapper: {
      marginLeft: "33px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "21px",
      },
    },
    labelText: {
      "&.MuiTypography-root": {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        marginBottom: "25px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          lineHeight: "18px",
          marginBottom: "25px",
        },
      },
    },
    btnWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "12px",
        marginTop: "16px",
      },
    },
    btn: {
      "&.MuiButton-root": {
        textTransform: "capitalize",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "18px",
        height: "36px",
        marginBottom: 15,
      },
      [theme.breakpoints.down("sm")]: {
        "&.MuiButton-root": {
          fontSize: "10px",
          lineHeight: "15px",
          height: "30px",
        },
      },
    },
  }),
  {
    name: "MuiCustomOnboarding",
  }
);
const OnBoardingList = () => {
  const {
    salesChannelsState: { userAccountDetails },
  } = useSalesChannel();
  const { extInstalled } = useExtension();
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <Box>
      <Paper
        elevation={0}
        className={classes.outerWrapper}
        sx={{
          backgroundColor: "background.secondary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "16px 20px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.headingText}>
            Getting Started Checklist
          </Typography>
          {show ? (
            <ExpandLess onClick={handleShow} />
          ) : (
            <ExpandMore onClick={handleShow} />
          )}
        </Box>
        {/* //Wrapper */}
        {show && (
          <>
            <Box className={classes.checkboxWrapper}>
              <FormGroup>
                <FormControlLabel
                  control={<></>}
                  label={
                    <Typography
                      className={classes.labelText}
                      sx={{
                        textDecoration:
                          userAccountDetails?.sales_channels?.length > 0
                            ? "line-through"
                            : "none",
                      }}
                    >
                      Connect your Etsy Shop
                    </Typography>
                  }
                />

                <FormControlLabel
                  control={<></>}
                  label={
                    <Typography
                      className={classes.labelText}
                      sx={{
                        textDecoration: extInstalled ? "line-through" : "none",
                      }}
                    >
                      Install your EverBee Chrome Extension
                    </Typography>
                  }
                />

                <FormControlLabel
                  control={<></>}
                  label={
                    <Typography
                      className={classes.labelText}
                      sx={{
                        textDecoration:
                          userAccountDetails?.usage_details
                            ?.product_analytics_feature > 0
                            ? "line-through"
                            : "none",
                      }}
                    >
                      Make a search in Product Analytics
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<></>}
                  label={
                    <Typography
                      className={classes.labelText}
                      sx={{
                        textDecoration:
                          userAccountDetails?.usage_details
                            ?.product_analytics_feature > 1
                            ? "line-through"
                            : "none",
                      }}
                    >
                      Analyze a Competitor Shop in Product Analytics
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            {/* <Box className={classes.btnWrapper}>
              <Button
                variant="outlined"
                size="small"
                className={classes.btn}
                sx={{
                  color: "fontColor.outlinedBtnFontColor",
                }}
              >
                Mark as Completed
              </Button>
            </Box> */}
          </>
        )}
      </Paper>
    </Box>
  );
};

export default OnBoardingList;
